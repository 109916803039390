// GUI styles of the project
// For example, styles of the links, buttons and so on.
a {
  @include customLink();
  font-family: inherit;
  font-weight: inherit;
  //color: inherit;
  color: $purple;
  border: none;
  outline: none;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    @include customLinkHover();
  }
}

button {
  font: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  transition: 0.3s;
}

// Стили для чекбоксов и радиокнопок
// Для работы кладем i после кнопки внутрь label
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + i {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 20px;
  height: 20px;

  border: 1px solid rgba(#283772, 0.2);
  box-sizing: border-box;
  //border-radius: 5px;

  @media (min-width: $laptop) {
    margin-right: 13px;
  }
}

input[type="checkbox"]:checked + i::after {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url("img/form/accept.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + i {
  flex-grow: 1;
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 21px;
  height: 21px;
  background-color: $white;
  border-radius: 50%;
}

input[type="radio"]:checked + i::after {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 6px;
  left: 6px;
  background-color: $firstColor;
  border-radius: 50%;
}

input[type="text"],
input[type="email"],
input[type="number"] {
  display: block;
  width: 100%;
  height: 50px;
  background: none;
  border: none;
  border-bottom: 1px solid rgba(#000000, 0.3);
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}


input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}
