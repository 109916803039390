@import "src/static/sass/basic/common";
@import "src/static/sass/swiper-bundle.min";
//@import "src/static/sass/basic/jquery.background-video";
//@import "src/static/sass/paginationjs.min.scss";

.container {
  display: block;
  position: relative;
  margin: 0 auto;
  width: $mobile;
  padding: 0 20px;
  z-index: 5;

  @media (min-width: $tablet) {
    width: $tablet;
    padding: 0 30px;
  }

  @media (min-width: $laptop) {
    width: $laptop;
    padding: 0 30px;
  }
}

.page--scroll {
  overflow-y: hidden;
}

.list {
  border-radius: 30px;
  padding: 20px;
  margin: 100px 20px;
  background-color: #efefef;
  flex-grow: 1;

  @media (min-width: $laptop) {
    width: 600px;
    padding: 30px;
    margin: 100px;
  }
}

.list li {
  margin-bottom: 20px;
}

.list a {
  font-size: 30px;
  color: #171C8F;
  font-weight: 500;
}

.decor {
  display: none;

  @media (min-width: $laptop) {
    display: block;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
    animation: ease-in-out infinite both;
  }
}

.formBlock {
  width: 360px;
  margin: 0 auto;

  @media (min-width: $tablet) {
    //width: 334px;
  }

  @media (min-width: $laptop) {
    width: 590px;
  }
}

.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(white, 0.5);

  &::after {
    content: '';
    font-size: 10px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    text-indent: -9999em;
    animation: mulShdSpin 1.1s infinite ease;
    transform: translateZ(0);
  }
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #003C96, 1.8em -1.8em 0 0em rgba(#003C96, 0.2), 2.5em 0em 0 0em rgba(#003C96, 0.2), 1.75em 1.75em 0 0em rgba(#003C96, 0.2), 0em 2.5em 0 0em rgba(#003C96, 0.2), -1.8em 1.8em 0 0em rgba(#003C96, 0.2), -2.6em 0em 0 0em rgba(#003C96, 0.5), -1.8em -1.8em 0 0em rgba(#003C96, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(#003C96, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(#003C96, 0.2), 1.75em 1.75em 0 0em rgba(#003C96, 0.2), 0em 2.5em 0 0em rgba(#003C96, 0.2), -1.8em 1.8em 0 0em rgba(#003C96, 0.2), -2.6em 0em 0 0em rgba(#003C96, 0.2), -1.8em -1.8em 0 0em rgba(#003C96, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(#003C96, 0.5), 1.8em -1.8em 0 0em rgba(#003C96, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(#003C96, 0.2), 0em 2.5em 0 0em rgba(#003C96, 0.2), -1.8em 1.8em 0 0em rgba(#003C96, 0.2), -2.6em 0em 0 0em rgba(#003C96, 0.2), -1.8em -1.8em 0 0em rgba(#003C96, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(#003C96, 0.2), 1.8em -1.8em 0 0em rgba(#003C96, 0.5), 2.5em 0em 0 0em rgba(#003C96, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(#003C96, 0.2), -1.8em 1.8em 0 0em rgba(#003C96, 0.2), -2.6em 0em 0 0em rgba(#003C96, 0.2), -1.8em -1.8em 0 0em rgba(#003C96, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(#003C96, 0.2), 1.8em -1.8em 0 0em rgba(#003C96, 0.2), 2.5em 0em 0 0em rgba(#003C96, 0.5), 1.75em 1.75em 0 0em rgba(#003C96, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(#003C96, 0.2), -2.6em 0em 0 0em rgba(#003C96, 0.2), -1.8em -1.8em 0 0em rgba(#003C96, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(#003C96, 0.2), 1.8em -1.8em 0 0em rgba(#003C96, 0.2), 2.5em 0em 0 0em rgba(#003C96, 0.2), 1.75em 1.75em 0 0em rgba(#003C96, 0.5), 0em 2.5em 0 0em rgba(#003C96, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(#003C96, 0.2), -1.8em -1.8em 0 0em rgba(#003C96, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(#003C96, 0.2), 1.8em -1.8em 0 0em rgba(#003C96, 0.2), 2.5em 0em 0 0em rgba(#003C96, 0.2), 1.75em 1.75em 0 0em rgba(#003C96, 0.2), 0em 2.5em 0 0em rgba(#003C96, 0.5), -1.8em 1.8em 0 0em rgba(#003C96, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(#003C96, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(#003C96, 0.2), 1.8em -1.8em 0 0em rgba(#003C96, 0.2), 2.5em 0em 0 0em rgba(#003C96, 0.2), 1.75em 1.75em 0 0em rgba(#003C96, 0.2), 0em 2.5em 0 0em rgba(#003C96, 0.2), -1.8em 1.8em 0 0em rgba(#003C96, 0.5), -2.6em 0em 0 0em rgba(#003C96, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

// normalize-scss
@import "node_modules/normalize-scss/sass/_normalize";
@include normalize();

// Шрифты
@import "src/static/sass/basic/_fonts";
@import "src/static/sass/basic/_variables";
@import "src/static/sass/basic/_mixins";
@import "src/static/sass/basic/_global";
@import "src/static/sass/basic/_ui";

@import "src/static/sass/basic/_animation";
@keyframes rotate1 {
  from {transform: rotate(10deg)}
  to {transform: rotate(-10deg)}
}

@keyframes rotate2 {
  from {transform: rotate(5deg)}
  to {transform: rotate(-5deg)}
}

@keyframes rotate360 {
  from {transform: rotate(0)}
  to {transform: rotate(360deg)}
}

@keyframes rotate360left {
  from {transform: rotate(0)}
  to {transform: rotate(-360deg)}
}

@keyframes scale {
  from {transform: scale(1.05)}
  to {transform: scale(0.95)}
}

@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}

@keyframes move {
  from {transform: translateY(-10px)}
  to {transform: translateY(10px)}
}
// Furura PT

@font-face {
    font-family: 'Aeroport';
    src: url("fonts/Aeroport.woff2") format("woff2"),
    url("fonts/Aeroport.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeroport';
    src: url("fonts/Aeroport-Medium.woff2") format("woff2"),
    url("fonts/Aeroport-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeroport';
    src: url("fonts/Aeroport-Bold.woff2") format("woff2"),
    url("fonts/Aeroport-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
// Border-box на html и все остальные элементы наследуют свойство.
// Позволяет добавить элемент с другим box-sizing.
html {
  height: 100%; // min-height: 100%;
  box-sizing: border-box;
  font-size: 10px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $basicFontFamily;
  font-size: 17px;
  line-height: 125%;
  letter-spacing: -0.03em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  text-align: center;

  @media (min-width: $tablet) {

  }

  @media (min-width: $laptop) {
    font-size: 30px;
    line-height: 120%;
  }
}

.page__wrapper {
  min-width: $mobile;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  min-height: 100vh;

  overflow: hidden;

  &.isStub {
    padding: 0;
  }
}

.title {
  font-weight: 500;
  font-size: 30px;
  line-height: 105%;
  letter-spacing: -0.04em;
  text-align: center;

  @media (min-width: $tablet) {
    font-size: 35px;
  }

  @media (min-width: $laptop) {
    font-size: 45px;
  }
}

.img {
  position: relative;
  display: block;

  picture {
    display: block;
    width: 100%;
    height: 100%;
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

ol,
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

.text-hidden {
  height: 0;
  font-size: 0;
  visibility: hidden;
  opacity: 0;
}

.swiper-pagination {
  pointer-events: none;
}

.swiper-pagination-bullet {
  width: 22px;
  height: 2px;
  border-radius: 0;
  background-color: $purple;
  pointer-events: auto;

  @media (min-width: $laptop) {
    width: 28px;
  }
}

// You can add your own mixins here:

@mixin iphone() {
  @media screen and (min-width: $iphone) {
    @content;
  }
}

@mixin tablet() {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin laptop() {
  @media screen and (min-width: $laptop) {
    @content;
  }
}

@mixin laptopPro() {
  @media screen and (min-width: $laptop-pro) {
    @content;
  }
}

@mixin desktop() {
  @media screen and (min-width: 1240px) {
    @content;
  }
}

// Can use drop-shadow here
@mixin shadow() {
  @supports not (-ms-high-contrast: none) {
    @content;
  }
}

@mixin flex($way, $vposition, $hposition) {
  display: flex;
  flex-direction: $way;
  justify-content: $vposition;
  align-items: $hposition;
}

@mixin customLink($lineGap: 2px) {
  margin-bottom: -$lineGap;
  padding-bottom: $lineGap;
  line-height: 120%;
  background-image: linear-gradient(currentColor, currentColor);
  background-size: 100% 1px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.3s ease;
}

@mixin customLinkHover() {
  //background-size: 0 1px;
  background-size: 100% 0;
}

@mixin wave-before($name: 'wave-white', $bottom: -1px, $bottomLaptop: -2px) {
  content: '';
  position: absolute;
  bottom: $bottom;
  left: 0;
  right: 0;
  height: 58px;
  background-image: url("img/svg/" + $name + "-m.svg");
  background-position: top center;
  background-repeat: repeat-x;

  @media (min-width: $tablet) {
    bottom: $bottomLaptop;
    height: 145px;
    background-image: url("img/svg/" + $name + "-d.svg");
  }
}

@mixin wave-before-top() {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-position: bottom center;
  background-size: contain;
  background-repeat: repeat-x;
  pointer-events: none;
}

// GUI styles of the project
// For example, styles of the links, buttons and so on.
a {
  @include customLink();
  font-family: inherit;
  font-weight: inherit;
  //color: inherit;
  color: $purple;
  border: none;
  outline: none;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    @include customLinkHover();
  }
}

button {
  font: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  transition: 0.3s;
}

// Стили для чекбоксов и радиокнопок
// Для работы кладем i после кнопки внутрь label
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + i {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 20px;
  height: 20px;

  border: 1px solid rgba(#283772, 0.2);
  box-sizing: border-box;
  //border-radius: 5px;

  @media (min-width: $laptop) {
    margin-right: 13px;
  }
}

input[type="checkbox"]:checked + i::after {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url("img/form/accept.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + i {
  flex-grow: 1;
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 21px;
  height: 21px;
  background-color: $white;
  border-radius: 50%;
}

input[type="radio"]:checked + i::after {
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 6px;
  left: 6px;
  background-color: $firstColor;
  border-radius: 50%;
}

input[type="text"],
input[type="email"],
input[type="number"] {
  display: block;
  width: 100%;
  height: 50px;
  background: none;
  border: none;
  border-bottom: 1px solid rgba(#000000, 0.3);
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}


input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

// File for scss-vars.
// For example: main colors, paddings and so on.
// Basic <body> styles
$basicFontFamily: 'Aeroport', sans-serif; // Берем нужный шрифт из переменной в fonts.scss
$basicFontSize: 15px; // Базовый размер шрифта
$basicLineHeight: 120%;
// Screen sizes
$mobile: 390px;
$iphone: 375px;
$tablet: 768px;
$laptop: 1260px;
$laptop-pro: 1440px;
$desktop-m: 1600px;
$desktop: 1920px;
// Colors
$black: #000;
$white: #fff;
$firstColor: #f5f5f5;
$secondColor: #000;
$thirdColor: #000;
$purple: #965EEB;

$ease: 0.2s ease-in-out;

@mixin bgi($image, $format) {
  background-image: url('img/advideo/'+ $image + '.'+ $format);

  @supports (background-image: url('test.webp')) {
    background-image: url('img/advideo/'+ $image + '.webp');
  }
}

// @include bgi('background', 'png');


.advideo {
  padding: 60px 0;

  

  @media (min-width: $tablet) {}

  @media (min-width: $laptop) {
    padding: 100px 0;
  }
}

.advideo__title {
  margin-bottom: 20px;

  &::after {
    content: '';
    background-image: url('img/advideo/heart.svg');
    background-repeat: no-repeat;
    width: 16px;
    height: 15px;
    background-size: contain;
    display: inline-block;
    margin-left: 10px;
  }

  @media (min-width: $tablet) {
    font-size: 30px;

    br.br-t {
      display: none;
    }
  }

  @media (min-width: $laptop) {
    margin-bottom: 40px;
    font-size: 45px;

    br.br-t {
      display: block;
    }

    &::after {
      width: 25px;
      height: 22px;
    }
  }
}

.advideo__text {
  @media (min-width: $laptop) {
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
  }
}

.advideo__container {
  display: flex;
  flex-direction: column;
  gap: 50px;

  @media (min-width: $tablet) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
  }

  @media (min-width: $laptop) {

  }
}

.advideo__box {
  text-align: center;
}

.advideo__video {
  .video {
    height: 196px;

    @media (min-width: $tablet) {
      width: 409px;
      height: 230px;
    }

    @media (min-width: $laptop) {
      width: 623px;
      height: 350px;
    }
  }
}
.cookieAlert {
  display: none;
  max-width: 400px;
  margin-right: 10px;
  padding: 15px 20px;
  position: fixed;
  bottom: 10px;
  left: 10px;
  font-size: 15px;
  line-height: 125%;
  letter-spacing: -0.03em;
  text-align: left;
  background-color: #EFEFEF;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 100;

  @media (min-width: $tablet) {
    margin-right: 30px;
    bottom: 30px;
    left: 30px;
  }

  @media (min-width: $laptop) {
    max-width: 465px;
    margin-right: 40px;
    padding: 25px 30px;
    bottom: 40px;
    left: 40px;
    font-size: 17px;
    line-height: 130%;
  }
}

.cookieAlert__inner {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (min-width: $tablet) {
    width: auto;
    display: flex;
    align-items: center;
  }
}

.cookieAlert__text {
  a {
    color: #00AAFF;
  }

  br {
    @media (min-width: $tablet) {
      display: none;
    }
  }
}

.cookieAlert__button.cookieAlert__button {
  min-width: auto !important;
  width: 110px !important;
  flex-shrink: 0;

  @media (min-width: $tablet) {
    width: 115px !important;
  }
}

.cookieAlert__close {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 0;
  background-image: url('img/cookieAlert/close.svg');
  background-repeat: no-repeat;

  &:hover,
  &:active,
  &:focus {
    opacity: 0.8;
  }

  @media (min-width: $tablet) {
    position: static;
    margin-left: 10px;
  }
}

@mixin bgi($image, $format) {
  background-image: url('img/button/' + $image + '.' + $format);

  @supports (background-image: url('test.webp')) {
    background-image: url('img/button/' + $image + '.webp');
  }
}
// @include bgi('background', 'png');


.button.button {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  width: 280px;
  margin: 0;
  padding: 10px 18px 13px;
  color: white;
  font-size: 17px;
  letter-spacing: normal;
  box-sizing: border-box;
  border-radius: 12px;
  //box-shadow: 0px 10px 30px rgba(0, 60, 150, 0.3), inset 0px -5px 4px rgba(255, 255, 255, 0.25);
  //background: radial-gradient(50% 125% at 50% 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%) center 100% / 200% 200%;
  background: black;
  //background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%) no-repeat;
  //box-shadow: 0px 10px 30px 0px rgba(0, 60, 150, 0.30);
  transition: $ease;

  @media (min-width: $tablet) {
    width: auto;
    min-width: 220px;
  }

  @media (min-width: $laptop) {
    min-width: 270px;
    padding: 14px 25px 17px;
    font-size: 20px;
    border-radius: 12px;
  }

  a {
    display: block;
    height: 100%;
    width: 100%;
  }

  img {
    margin-left: 10px;
  }

  &:hover,
  &:active,
  &:focus {
    //background-position: center 0;
    //background-position: center 50px;
    background-color: #292929;
  }

  &--primary {/*только в форме регистрации*/
    color: white;
    background-color: #E03333;
  }

  &--white {
    color: black;
    background-color: white;

    &:hover,
    &:active,
    &:focus {
      background-color: #E1E1E1;
    }
  }

  &--pulsating {
    animation: scale 2.5s ease-in-out infinite alternate;

    &:hover {
      animation-play-state: paused;
    }
  }

  &[disabled] {
    //opacity: 0.5;
    box-shadow: none;
    pointer-events: none;
  }
}

@mixin bgi($image, $format) {
  background-image: url('img/faq/'+ $image + '.'+ $format);

  @supports (background-image: url('test.webp')) {
    background-image: url('img/faq/'+ $image + '.webp');
  }
}

// @include bgi('background', 'png');


.faq {
  padding: 60px 0 0;
  background-color: #f5f5f5;
  border-radius: 30px 30px 0 0;

  @media (min-width: $tablet) {}

  @media (min-width: $laptop) {
    padding: 100px 0 0;
    border-radius: 60px 60px 0 0;
  }
}

.faq__title {
  margin-bottom: 40px;

  @media (min-width: $tablet) {}

  @media (min-width: $laptop) {
    margin-bottom: 60px;
  }
}

.faq__list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: $laptop) {
    gap: 32px;
  }
}

.faq__item {
  padding: 16px 24px;
  background-color: #fff;

  @media (min-width: $laptop) {
    padding: 30px;
  }

  &.open {
    .faq__itemTitle::after {
      transform: scale(1, -1);
      margin-top: 0;
    }
  }
}

.faq__itemTitle {
  display: flex;
  text-align: left;
  gap: 15px;
  font-size: 17px;
  line-height: 120%;
  align-items: center;
  cursor: pointer;

  &::after {
    content: '';
    flex-shrink: 0;
    width: 18px;
    height: 11px;
    margin-left: auto;
    background-image: url('img/faq/arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    transition: 0.3s;
    margin-top: 4px;

    @media (min-width: $laptop) {
      width: 19px;
      height: 13px;
    }
  }

  @media (min-width: $laptop) {
    font-size: 30px;
  }
}

.faq__itemText {
  display: none;
  padding-top: 15px;
  font-size: 13px;
  line-height: 120%;
  letter-spacing: -3%;
  text-align: left;

  @media (min-width: $laptop) {
    font-size: 20px;
  }

  p:not(:last-child) {
    padding-bottom: 10px;
  }
}
.footer {
  background-color: $firstColor;
  padding: 60px 0 40px 0;

  @media (min-width: $tablet) {
    padding: 80px 0 40px;
  }

  @media (min-width: $laptop) {
    padding: 120px 0 60px;
  }

  @media (min-width: $laptop-pro) {}

  b {
    font-weight: 500;
  }
}

.footer__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  flex-wrap: wrap;

  @media (min-width: $tablet) {
    position: relative;
    justify-content: space-between;
    flex-wrap: nowrap;
    text-align: left;
  }

  @media (min-width: $laptop) {
    gap: 94px;
  }
}

.footer__logo {
  width: 100%;

  img {
    margin-left: auto;
    margin-right: auto;
    width: 79px;
  }

  @media (min-width: $tablet) {
    width: 99px;
    flex-shrink: 0;
  }

  @media (min-width: $laptop) {
    width: 99px;

    img {
      width: 99px;
    }
  }
}

.footer__info {
  font-size: 13px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: rgba(black, 0.5);

  @media (min-width: $tablet) {
    font-size: 10px;
  }

  @media (min-width: $laptop) {
    max-width: 400px;
    font-size: 13px;
  }
}

.footer__rules {
  letter-spacing: -4%;

  @media (min-width: $tablet) {
    flex-shrink: 0;
  }

  @media (min-width: $laptop) {
    font-size: 20px;
  }
}

.footer__socials {
  display: flex;
  align-items: baseline;

  @media (min-width: $tablet) {
    flex-shrink: 0;
  }

  @media (min-width: $laptop) {}

  span {
    display: block;

    font-size: 17px;
    line-height: 120%;
    letter-spacing: -4%;
    font-weight: 500;
    white-space: nowrap;

    @media (min-width: $tablet) {



      line-height: 120%;
    }

    @media (min-width: $laptop) {
      font-size: 20px;
    }
  }
}
@mixin bgi($image, $format) {
  background-image: url('img/ideas/' + $image + '.' + $format);

  @supports (background-image: url('test.webp')) {
    background-image: url('img/ideas/' + $image + '.webp');
  }
}
// @include bgi('background', 'png');


.ideas {
  padding: 60px 0;

  @media (min-width: $tablet) {
    padding: 80px 0;
  }

  @media (min-width: $laptop) {
    padding: 110px 0 100px;
  }
}

.ideas__text {
  margin-bottom: 20px;
  text-align: center;
}

.ideas__title {
  @media (min-width: $tablet) {
    width: 600px;
    margin: 0 auto;
  }

  @media (min-width: $laptop) {
    width: 860px;
  }
}

.ideas__slider {
  position: relative;
  margin-top: 44px;

  @media (min-width: $laptop) {
    margin-top: 58px;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
  }

  .swiper-slide:not(.swiper-slide-active) {
    pointer-events: none;
  }

  .swiper-slide:nth-child(even) {
    .ideas__item {
      @media (min-width: $tablet) {
        transform: rotate(5deg);
      }
    }
  }

  .swiper-slide:nth-child(odd) {
    .ideas__item {
      @media (min-width: $tablet) {
        transform: rotate(-5deg);
      }
    }
  }

  .swiper-slide-active.swiper-slide-active {
    .ideas__item {
      @media (min-width: $tablet) {
        transform: rotate(0);
      }
    }
  }
}

.ideas__item {
  position: relative;
  box-sizing: border-box;
  width: 250px;
  height: 320px;
  padding: 20px 15px;
  border-radius: 15px;
  overflow: hidden;
  transition: $ease;

  @media (min-width: $laptop) {
    width: 350px;
    height: 400px;
    padding: 30px;
    border-radius: 30px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    box-shadow: inset 1px 1px white;
    pointer-events: none;

    @media (min-width: $laptop) {
      border-radius: 30px;
    }
  }
}

.ideas__item--1 {
  background-color: #8A58D6;
}

.ideas__item--2 {
  background-color: #FF4053;

  .ideas__itemImageLeft {
    gap: 40px;

    @media (min-width: $laptop) {
      gap: 49px;
    }
  }
}

.ideas__item--3 {
  background-color: #04E061;

  .ideas__itemImageLeft {
    gap: 33px;

    @media (min-width: $laptop) {
      gap: 49px;
    }
  }
}

.ideas__item--4 {
  background-color: #00AAFF;

  .ideas__itemImageLeft {
    gap: 46px;

    @media (min-width: $laptop) {
      gap: 64px;
    }
  }
}

.ideas__item--5 {
  background-color: #8A58D6;
}

.ideas__itemImage {
  position: relative;
  display: flex;
  gap: 15px;
  transition: $ease;

  @media (min-width: $laptop) {
    gap: 20px;
  }

  picture {
    display: block;
  }

  a {
    display: block;
    margin: 0;
    padding: 0;
    background: none;
    transition: $ease;
  }

  a[href]:hover {
    @media (min-width: $laptop) {
      transform: scale(1.05);
    }
  }
}

.ideas__itemImageLeft {
  display: flex;
  flex-direction: column;
  gap: 53px;

  @media (min-width: $laptop) {
    gap: 49px;
  }

  a:first-child img {
    border-radius: 80px;
  }

  a:last-child img {
    border-radius: 30px;
  }
}

.ideas__itemImageRight {
  img {
    border-radius: 900px;
  }
}

.ideas__itemPicture {
  &--m {
    @media (min-width: $laptop) {
      display: none;
    }
  }

  &--d {
    display: none;

    @media (min-width: $laptop) {
      display: block;
    }
  }
}

.ideas__itemImageText {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ideas__navigation.ideas__navigation {
  justify-content: center;
  gap: 40px;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 360px;

  @media (min-width: $tablet) {
    bottom: -60px;
  }

  @media (min-width: $laptop) {
    bottom: -70px;
  }
}

.ideas__clickAreas {
  display: none;

  @media (min-width: $laptop) {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    pointer-events: none;
  }
}

.ideas__clickArea {
  @media (min-width: $laptop) {
    width: calc(50% - 175px);
    height: auto;
    pointer-events: auto;
  }
}

.ideas__button {
  margin-top: 80px;
  text-align: center;

  @media (min-width: $laptop) {
    margin-top: 120px;
  }
}
@mixin bgi($image, $format) {
  background-image: url('img/header/' + $image + '.' + $format);

  @supports (background-image: url('test.webp')) {
    background-image: url('img/header/' + $image + '.webp');
  }
}
// @include bgi('background', 'png');

$headerPaddingM: 20px 20px 0;
$headerPaddingL: 30px 120px 0;

.header {
  position: relative;
  z-index: 100;
}

.header__overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(25px);
}

.header__logoPlace {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  //justify-content: center;
  align-items: center;
  padding: $headerPaddingM;
  pointer-events: none;

  @media (min-width: $laptop) {
    width: 1200px;
    margin: 0 auto;
    padding: $headerPaddingL;
    padding-left: 0;
    padding-right: 0;
  }
}

.header__logo {
  width: 99px;
  background: none;
  pointer-events: auto;

  @media (min-width: $tablet) {
    order: 10;
    margin-right: auto;
  }

  @media (min-width: $laptop) {
    width: 119px;
  }
}

.header__content {
  //position: fixed;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: $headerPaddingM;
  pointer-events: none;

  @media (min-width: $tablet) {
    justify-content: flex-end;
    gap: 20px;
  }

  @media (min-width: $laptop) {
    display: none;
    padding: $headerPaddingL;
  }
}

.header__btn {
  position: relative;
  width: 25px;
  height: 25px;
  margin-left: auto;
  font-size: 0;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  cursor: pointer;
  pointer-events: auto;

  @media (min-width: $laptop) {
    width: 60px;
    height: 60px;
  }

  /*&::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    opacity: 0;
    transition: 0.3s;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }*/

  &--menu {
    background-image: url('img/header/menu.svg');

    @media (min-width: $tablet) {
      order: 20;
    }

    /*&::before {
      background-image: url('img/header/menu-hover.svg');
    }*/
  }

  &--close {
    display: none;
    background-image: url('img/header/close.svg');

    @media (min-width: $tablet) {
      order: 20;
    }

    /*&::before {
      background-image: url('img/header/close-hover.svg');
    }*/
  }
}

.header__menu {
  position: fixed;
  left: 0;
  //bottom: 0;
  top: -500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  width: 100%;
  padding: 100px 20px 30px;
  border-radius: 0 0 20px 20px;
  overflow-y: auto;
  transition: 0.5s;

  @media (min-width: $tablet) {
    padding: 110px 50px 30px;
    border-radius: 30px 0 0 30px;
  }

  @media (min-width: $laptop) {
    flex-direction: row;
    position: absolute;
    top: 30px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    width: 1200px;
    padding: 0 0 0 130px;
    background: none;
    overflow-y: visible;
  }
}

.header__menuList {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;

  @media (min-width: $tablet) {
    //width: 360px;
    //gap: 10px;
  }

  @media (min-width: $laptop) {
    gap: 35px;
    flex-direction: row;
    width: auto;
    margin-left: auto;
  }
}

.header__menuItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  font-size: 20px;
  line-height: 120%;
  color: black;
  background: none;
  transition: 0.3s;

  @media (min-width: $tablet) {

  }

  @media (min-width: $laptop) {
    display: inline;
    padding: 0;
    @include customLink(10px);
    background-image: linear-gradient(rgba(black, 0.3), rgba(black, 0.3));
    font-weight: 500;
    font-size: 17px;
    line-height: 120%;
    letter-spacing: -0.03em;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    color: $purple;

    @media (min-width: $laptop) {
      color: black;
      @include customLinkHover();
    }
  }
}

.header__menuButton {
  width: 100%;
  margin-top: 30px;
  text-align: center;

  @media (min-width: $laptop) {
    display: none;
  }
}


.header.open {
  .header__overlay {
    display: block;
  }

  .header__menu {
    display: flex;
    top: 0;
  }

  .header__btn--menu {
    display: none;
  }

  .header__btn--close {
    display: block;
  }

  .header__logoPlace {
    position: fixed;
  }

  /*.header__logo {
    @media (max-width: 767px) {
      display: none;
    }
  }*/
}
@mixin bgi($image, $format) {
  background-image: url('img/navigation/' + $image + '.' + $format);

  @supports (background-image: url('test.webp')) {
    background-image: url('img/navigation/' + $image + '.webp');
  }
}
// @include bgi('background', 'png');


.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  pointer-events: none;

  @media (min-width: $tablet) {

  }

  @media (min-width: $laptop) {

  }
}

.navigationBtn {
  width: 40px;
  height: 40px;
  background-image: url('img/navigation/next.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  pointer-events: auto;

  &--prev {
    transform: rotate(180deg);
  }

  &.swiper-button-disabled {
    opacity: 0.5;
  }
}
.popupContent {
  display: block;
  position: relative;
  z-index: 500;

  &--postcards {
    .popupContent_popup_container {
      //@media (max-width: 767px) {
        overflow: hidden;
      //}
    }
  }

  &--video {
    .popupContent_popup_container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.popupContent_wrapper {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background-color: rgba(#003C96, 0.7);
  //background-image: url('img/popupContent/blur.svg');
  //background-position: center;
  //background-size: 100% 100%;
  //background-repeat: no-repeat;
  backdrop-filter: blur(11px);
}

.popupContent_close {
  position: absolute;
  top: 30px;
  right: 30px;
  background-image: url('img/popupContent/close.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 50px;
  height: 50px;
  z-index: 400;

  @include tablet() {
    transform: none;
  }

  @media (min-width: $laptop) {
    width: 60px;
    height: 60px;
    right: 60px;
    top: 60px;
  }
}

.popupContent_popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: none;

  &--active {
    display: flex;
  }

}

.popupContent_popup_container {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  padding-top: 100px;
  overflow: auto;

  @include tablet() {
    padding-top: 0;
  }

  /*@supports not (-ms-high-contrast: none) {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 280px;
      height: 350px;
      background-color: #478BF1;
      opacity: 0.5;
      border-radius: 50%;
      filter: blur(125px);

      @media (min-width: $tablet) {
        width: 768px;
        height: 562px;
      }

      @media (min-width: $laptop) {
        width: 1260px;
        height: 562px;
      }
    }
  }*/
}

.popupContent_popup_content {
  position: relative;
  //position: absolute;
  //left: 0;
  //top: 0;
  //width: 100%;
  //height: 100%;
  z-index: 300;
  width: 320px;
  margin: auto;
  padding: 30px 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: white;

  @include tablet() {
    width: 400px;
    padding: 35px 40px 30px;
    height: auto;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    border-radius: 50px;
  }

  @media (min-width: $laptop) {
    width: 460px;
    padding: 35px 40px 45px;
  }

  &--email {
    padding: 0;

    @include tablet() {
      width: 280px;
    }

    @media (min-width: $laptop) {
      width: 470px;
      border-radius: 60px;
    }
  }

  &--final,
  &--postcardsSent {
    padding: 0;
    border-radius: 0;
    background: none;

    @include tablet() {
      width: 590px;
    }

    .popupContent_popup_contentInner {
      width: 100%;
    }
  }

  &--postcards {
    padding: 0;
    border-radius: 0;
    background: none;
  }
}

.popupContent__header {
  padding: 20px;
  background-color: #FFB700;
  border-radius: 30px 30px 0 0;

  @media (min-width: $laptop) {
    padding: 30px;
  }
}

.popupContent__content {
  padding: 20px;
  background-color: white;
  border-radius: 0 0 30px 30px;

  @include tablet() {
    padding: 30px;
  }

  @media (min-width: $laptop) {
    padding: 50px;
  }
}

.popupContent_popup_title {
  margin-bottom: 20px;
  font-weight: 450;
  font-size: 28px;
  line-height: 100%;
  text-align: center;
  color: #003C96;

  @include tablet() {
    font-size: 30px;
    line-height: 100%;
  }

  @media (min-width: $laptop) {
    margin-bottom: 30px;
    font-size: 40px;
    line-height: 100%;
  }
}

.popupContent_popup_list {
  counter-reset: reg;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  margin: 0;
  padding: 0;
}

.popupContent_popup_item {
  counter-increment: reg;
  padding: 10px;
  background-color: #EDF9FD;
  border-radius: 10px;

  @include laptop() {
    padding: 20px 25px;
  }

  &--blue {
    color: white;
    background-color: #007BFB;
  }

  &--dark {
    color: white;
    background-color: #003C96;
  }

  ol &::after {
    content: counter(reg)')';
    position: absolute;
    left: 0;
    top: 0;
    font-weight: 600;
    font-size: 17px;
    line-height: 110%;
  }
}

.popupContent_popup_subtitle {
  margin-bottom: 10px;
  margin-left: -20px;
  font-size: 20px;
}

.popupContent_popup_itemTitle {
  margin-bottom: 10px;
  font-weight: 450;
  font-size: 17px;
  line-height: 17px;

  @include laptop() {
    font-size: 20px;
    line-height: 20px;
  }
}

.popupContent_popup_itemList {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.popupContent_popup_itemLine {
  display: flex;
  gap: 10px;

  &::before {
    content: '';
    flex-grow: 1;
    border-bottom-style: dotted;
    border-bottom-width: 1px;
    order: 20;
  }
}

.popupContent_popup_desc {
  font-size: 13px;
  line-height: 110%;
  text-align: left;

  @include laptop() {
    font-size: 17px;
  }

  &--left {
    flex-shrink: 0;
    order: 10;
  }

  &--right {
    font-weight: 500;
    text-align: right;
    order: 30;
  }

  b {
    font-weight: 500;
  }
}

.popupContent__button {
  margin-top: 20px;

  @include laptop() {
    margin-top: 35px;
  }
}

.popupContent__bottom {
  width: 320px;
  margin: 20px 0 -30px;
  padding: 20px;
  font-weight: 700;
  font-size: 15px;
  line-height: 120%;
  text-align: center;
  color: #26834C;
  background-color: #D9E29B;

  @include tablet() {
    width: 480px;
    margin-top: 30px;
  }

  @include laptop() {
    margin-bottom: -50px;
    font-size: 17px;
    line-height: 120%;
  }
}


.popupContent--reg {
  width: 360px;

  .popupContent_popup_container {
    @media (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .popupContent_popup_content {
    flex-direction: column;
    width: 360px;
    overflow: hidden;

    @media (max-width: 767px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    @include tablet() {
      width: 480px;
    }

    @include laptop() {
      width: 640px;
    }
  }

  /*.popupContent_popup_title {
    margin-bottom: 20px;
    font-size: 26px;

    @include tablet() {
      margin-bottom: 30px;
      font-size: 30px;
      line-height: 100%;
    }
  }*/
}



.popupContent_popup_content--video {
  width: 360px;
  padding: 0;
  background: none;

  @include tablet() {
    width: 730px;
  }

  @include laptop() {
    width: 900px;
  }
}


.popupContent--envelope {
  .popupContent_wrapper {
    background-color: rgba(#671E75, 0.6);
    backdrop-filter: blur(7px);
  }

  .popupContent_popup_content {
    width: 100%;
    padding: 0;
    background: transparent;
  }
}

@mixin bgi($image, $format) {
  background-image: url('img/rules/' + $image + '.' + $format);

  @supports (background-image: url('test.webp')) {
    background-image: url('img/rules/' + $image + '.webp');
  }
}
// @include bgi('background', 'png');


.rules {
  padding: 60px 0;
  background-color: #EFEFEF;
  border-radius: 30px;

  @media (min-width: $tablet) {

  }

  @media (min-width: $laptop) {
    padding: 100px 0;
    border-radius: 60px;
  }
}

.rules__title {
  margin-bottom: 15px;
  br.br-t {
    display: none;
  }

  @media (min-width: $tablet) {
    font-size: 30px;
    line-height: 105%;
    letter-spacing: -4%;
    br.br-m {
      display: none;
    }

    br.br-t {
      display: block;
    }
  }

  @media (min-width: $laptop) {
    font-size: 45px;
    br.br-t {
      display: none;
    }
  }
}

.rules__text {
  font-size: 17px;
  line-height: 125%;
  text-align: center;
  letter-spacing: -3%;

  span {
    color: white;
    display: inline-block;
    background-color: #00AAFF;
    padding: .2rem .7rem .3rem;
    border-radius: 20px;
  }

  @media (min-width: $tablet) {
  
    span {
      font-weight: 700;
    }
  }

  @media (min-width: $laptop) {
    font-size: 30px;
  }
}

.rules__cards {
  margin: 20px 0 30px;

  @media (min-width: $tablet) {
    margin: 50px 0 40px;
    display: flex;
    flex-direction: row;
  }

  @media (min-width: $laptop) {
    margin: 100px 0 40px;
    
  }
}

.rules__card {
  background-color: white;
  border-radius: 20px;
  padding: 25px 22px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: top;
  text-align: left;
  gap: 19px;
  border-top: 2px solid #EFEFEF;
  height: fit-content;

  &:not(:first-child) {
    margin-top: -30px;
  }

  img {
    width: 32px;
    height: 32px;

    @media (min-width: $laptop) {
     width: 50px;
     height: 50px;
    }
  }

  &_2 {
    .rules__card-title {
      position: relative;
      width: 200px;
      &::before {
        content: url('img/rules/arrow.svg');
        position: absolute;
        top: 40px;
        left: 170px;
        transform: rotateY(180deg);
      }
    }

    .rules__card-desc {
      width: 200px;
    }
  }
  
  &_last {
    .rules__card-desc {
      &::after {
        content: '';
        display: inline-block;
        width: 13px;
        height: 12px;
        margin-left: 5px;
        background-image: url('img/rules/text-heart.svg');
        background-size: contain;
        background-repeat: no-repeat;
        transform: translate(0, 2px);
      }
    }
  }

  @media (min-width: $tablet) {
   flex-direction: column;
   align-items: center;
   width: 200px;
   margin-left: auto;
   margin-right: auto;
   text-align: center;
   border-left: 2px solid #EFEFEF;
   padding: 18px;

   &_1 {
    transform: rotate(1deg);
    margin-top: -10px;
  }

  &_2 {
    transform: rotate(-2deg);

    .rules__card-title {
      width: unset;

      &::before {
        transform: unset;
        top: 64px;
        left: -10px;
      }
    }

    .rules__card-desc {
      width: unset;
    }
  }

  &_3 {
    transform: rotate(1deg);
  }

  &_last {
    transform: rotate(-1deg);
  }

  }

  @media (min-width: $laptop) {
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 44px 25px;
    width: 310px;

    &_1 {
      transform: rotate(2deg);
      margin-top: -20px;

      b {
        //display: block;
      }

      .rules__card-title {
        margin-bottom: 0;
      }
    }

    &_2 {
      .rules__card-title {
  
        &::before {
          left: 0px;
          top: 57px;
        }
      }
    }

    &_3 {
      .rules__card-title {
        margin-bottom: 0;
      }
      
    }
  }
}

.rules__card-title {
  line-height: 130%;
  letter-spacing: -3%;
  margin-bottom: 5px;
  @media (min-width: $laptop) {
    font-size: 20px;
    line-height: 130%;
    margin-bottom: 10px;
  }
}

.rules__card-desc {
  font-size: 15px;
  line-height: 125%;
  color: rgba(0, 0, 0, 0.7);

  @media (min-width: $laptop) {
    font-size: 17px;
    line-height: 125%;

  }
}

.rules__btns {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: $tablet) {
    flex-direction: row;
    justify-content: center;
  }

  @media (min-width: $laptop) {
    gap: 20px;
  }
}


.socials {
  display: block;
  margin-left: 15px;
}

.socials_socials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  li {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  a {
    transition: $ease;

    &:hover {
      opacity: 0.7;
    }
  }

  img {
    display: block;
    width: 100%;
  }
}

.socials__vk {
  width: 15px;
  height: 10px;

  @media (min-width: $laptop) {
    width: 18px;
    height: 12px;
  }
}

.socials__tg {
  width: 12px;
  height: 11px;

  @media (min-width: $laptop) {
    width: 14.5px;
    height: 13px;
  }
}
@mixin bgi($image, $format) {
  background-image: url('img/stories/' + $image + '.' + $format);

  @supports (background-image: url('test.webp')) {
    background-image: url('img/stories/' + $image + '.webp');
  }
}
// @include bgi('background', 'png');


.stories {
  padding: 60px 0;
  background-color: #EFEFEF;
  border-radius: 30px;

  @media (min-width: $tablet) {

  }

  @media (min-width: $laptop) {
    padding: 100px 0;
    border-radius: 60px;
  }
}

.stories__tabs {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.stories__tabItem {
  position: relative;
  width: 87px;

  @media (min-width: $laptop) {
    width: 170px;
  }

  &.active:not(.current) {
    cursor: pointer;
  }

  &.active:not(.current):hover {
    @media (min-width: $laptop) {
      .stories__tabItemImage {
        border-color: $purple;
        transform: scale(1.1);

        img {
          transform: scale(0.8);
        }
      }
    }
  }
}

.stories__tabItemImage {
  display: flex;
  width: 54px;
  height: 54px;
  margin: 0 auto;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: $ease;

  @media (min-width: $laptop) {
    width: 64px;
    height: 64px;
  }

  picture {
    display: flex;
  }

  img {
    width: 48px;
    height: 48px;
    margin: auto;
    border-radius: 50%;
    transition: $ease;

    @media (min-width: $laptop) {
      width: 56px;
      height: 56px;
    }
  }

  .stories__tabItem.current & {
    border-color: $purple;
  }
}

.stories__tabItemImage--empty {
  &::before {
    content: '';
    width: 48px;
    height: 48px;
    margin: auto;
    border-radius: 50%;
    background-image: url('img/stories/default.svg');
    background-size: contain;
    background-repeat: no-repeat;

    @media (min-width: $laptop) {
      width: 56px;
      height: 56px;
    }
  }
}

.stories__tabItem:nth-child(2) .stories__tabItemImage::before {
  transform: rotate(10deg);
}

.stories__tabItem:nth-child(3) .stories__tabItemImage::before {
  transform: rotate(-5deg);
}

.stories__tabItem:nth-child(4) .stories__tabItemImage::before {
  transform: rotate(15deg);
}

.stories__tabItemTitle {
  margin-top: 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 110%;
  letter-spacing: -0.04em;
  text-align: center;
  color: $purple;

  @media (min-width: $laptop) {
    margin-top: 15px;
    font-size: 25px;
    line-height: 120%;
  }

  .stories__tabItem:not(.active) & {
    color: rgba($purple, 0.5);
  }
}

.stories__tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  transition: $ease;

  @media (min-width: $laptop) {
    bottom: calc(100% - 7px);
  }

  &::before {
    content: '';
    position: absolute;
    top: calc(100% - 1px);
    left: 50%;
    transform: translateX(-50%);
    width: 45px;
    height: 18px;
    background-image: url('img/stories/tooltip-corner.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .stories__tabItem:not(.active):hover & {
    opacity: 1;
  }
}

.stories__tooltipBody {
  position: relative;
  width: 204px;
  padding: 13px 18px 15px;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-align: center;
  background-color: white;
  border-radius: 25px;

  @media (min-width: $laptop) {
    width: 277px;
    font-size: 20px;
    border-radius: 40px;
  }

  .stories__tabItem:first-child & {
    @media (max-width: 767px) {
      left: 50px;
    }
  }

  .stories__tabItem:last-child & {
    @media (max-width: 767px) {
      left: -50px;
    }
  }
}

.stories__sliderStartBg {
  @media (min-width: $tablet) {
    width: 350px;
    height: 200px;
    margin-top: -200px;
    margin-left: 50px;
    background-color: $purple;
    border-radius: 20px;
  }

  @media (min-width: $laptop) {
    width: 600px;
    height: 320px;
    margin-top: -320px;
    margin-left: 50px;
    border-radius: 30px;
  }
}

.stories__sliderStart {
  position: relative;
  z-index: 10;
  pointer-events: none;
}

.stories__sliderClickPrev {
  display: none;

  @media (min-width: $laptop) {
    display: block;
    position: absolute;
    top: -20px;
    bottom: -20px;
    left: 289px;
    width: 110px;
    pointer-events: auto;
  }
}

.stories__slider {
  margin-top: 20px;

  @media (min-width: $tablet) {
    margin-top: -210px;
    padding-left: 250px;
  }

  @media (min-width: $laptop) {
    margin-top: -365px;
    padding-left: 400px;
  }

  &::after {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .swiper-slide {
    overflow: visible;

    .stories__sliderItem,
    .video {
      @media (min-width: $tablet) {
        transform: scale(0.8);
      }
    }

    .video {
      @media (min-width: $laptop) {
        height: 360px;
        top: 25px;
      }
    }
  }

  .swiper-slide:not(.active) {
    display: none;
  }

  .swiper-slide-visible {
    .stories__sliderItem,
    .video {
      @media (min-width: $tablet) {
        transform: scale(1);
      }
    }

    .video {
      @media (min-width: $tablet) {
        left: -65px;
      }

      @media (min-width: $laptop) {
        left: -110px;
      }
    }
  }
}

.stories__sliderItem {
  box-sizing: border-box;
  width: 210px;
  height: 210px;
  border-radius: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  @media (min-width: $tablet) {
    width: 220px;
    height: 220px;
  }

  @media (min-width: $laptop) {
    width: 410px;
    height: 410px;
  }
}

.stories__sliderItem--start {
  display: flex;
  flex-direction: column;
  width: 201px;
  padding: 20px 22px;
  font-size: 17px;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-align: center;
  color: white;
  background-color: $purple;
  box-shadow: none;

  @media (min-width: $tablet) {
    width: 185px;
    height: 200px;
    margin-top: 65px;
    padding: 20px;
    pointer-events: auto;
  }

  @media (min-width: $laptop) {
    width: 289px;
    height: 320px;
    margin-top: 85px;
    padding: 50px 30px 40px;
    font-size: 25px;
  }

  &:not(.active) {
    @media (min-width: $tablet) {
      display: none;
    }
  }
}

.stories__sliderControls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: auto;

  @media (min-width: $laptop) {
    margin-bottom: -8px;
  }
}

.stories__sliderControlsText {
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  letter-spacing: -0.03em;
  opacity: 0.7;

  @media (min-width: $laptop) {
    font-size: 20px;
  }
}

.stories__sliderControlsArrow {
  width: 25px;
  height: 16px;
  font-size: 0;
  background-image: url('img/stories/next.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: $laptop) {
    width: 41px;
    height: 41px;
  }

  &--prev {
    transform: rotate(180deg);

    @media (max-width: 767px) {
      display: none;
    }
  }

  &.swiper-button-disabled {
    opacity: 0.3;
  }
}

.stories__sliderItemFancy {
  display: block;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.stories__sliderImage {
  display: block;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.stories__button {
  margin-top: 50px;
  text-align: center;
}

.stories__video {
  position: relative;
  margin-top: 50px;
  z-index: 20;

  @media (min-width: $tablet) {
    display: none;
  }
}

.stories__videoItem {
  &:not(.active) {
    display: none;
  }
}

.stories__logo {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;

    @media (min-width: $tablet) {
      z-index: 10;
    }
  }

  &._1 {
    &::after {
      bottom: -6px;
      right: 40px;
      width: 112px;
      height: 32px;
      transform: rotate(7deg);
      background-image: url('img/stories/decor1.svg');

      @media (min-width: $tablet) {
        right: 50%;
        bottom: -6px;
        transform: translateX(calc(50% + 85px)) rotate(7deg);
      }

      @media (min-width: $laptop) {
        bottom: -12px;
        transform: translateX(calc(50% + 145px)) rotate(7deg);
        width: 187px;
        height: 54px;
      }
    }
  }

  &._2 {
    &::after {
      bottom: -6px;
      right: -20px;
      width: 126px;
      height: 72px;
      transform: rotate(-7deg);
      background-image: url('img/stories/decor2.svg');

      @media (min-width: $tablet) {
        right: 50%;
        transform: translateX(calc(50% + 125px)) rotate(7deg);
      }

      @media (min-width: $laptop) {
        transform: translateX(calc(50% + 235px)) rotate(7deg);
        width: 186px;
        height: 107px;
      }
    }
  }
}

.stories-mobile {
  @media (min-width: $tablet) {
    display: none;
  }
}

.stories-desktop {
  @media (max-width: 767px) {
    display: none;
  }
}
@mixin bgi($image, $format) {
  background-image: url('img/video/' + $image + '.' + $format);

  @supports (background-image: url('test.webp')) {
    background-image: url('img/video/' + $image + '.webp');
  }
}
// @include bgi('background', 'png');


.video {
  display: block;
  position: relative;
  height: 220px;
  margin: 0;
  padding: 0;
  border-radius: 20px;
  background: black;
  overflow: hidden;
  cursor: pointer;

  @media (min-width: $tablet) {
    width: 340px;
    height: 220px;
  }

  @media (min-width: $laptop) {
    width: 620px;
    height: 361px;
    border-radius: 30px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(black, 0.3);
    background-image: url('img/video/play.svg');
    background-position: center;
    background-repeat: no-repeat;
    transition: $ease;
  }

  &:hover::after {
    transform: scale(1.1);
  }
}

.video__preview {
  display: block;
  width: 100%;
  height: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@mixin bgi($image, $format) {
  background-image: url('img/welcome/' + $image + '.' + $format);

  @supports (background-image: url('test.webp')) {
    background-image: url('img/welcome/' + $image + '.webp');
  }
}
// @include bgi('background', 'png');


.welcome {
  display: flex;
  flex-direction: column;
  padding: 70px 0 60px;

  @media (min-width: $tablet) {
    padding: 80px 0;
  }

  @media (min-width: $laptop) {
    padding: 120px 0;
  }

  .container {
    pointer-events: none;

    * {
      pointer-events: auto;
    }
  }
}

.welcome__title {
  font-size: 35px;
  letter-spacing: -0.05em;

  @media (min-width: $tablet) {
    width: 340px;
    font-size: 45px;
    line-height: 100%;
    text-align: left;
  }

  @media (min-width: $laptop) {
    width: 470px;
    font-size: 75px;
    line-height: 88%;
  }
}

.welcome__slider {
  margin-top: 40px;

  @media (min-width: $tablet) {
    margin-top: 30px;
    order: 30;
  }

  @media (min-width: $laptop) {
    margin-top: 0;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
  }

  .swiper-slide-active {
    z-index: 100;

    .welcome__item {
      transform: translate(0, 0);

      @media (min-width: $laptop) {
        transform: translate(50px, -50px);
      }
    }

    .welcome__itemWrap {
      transform: scale(1);

      @media (min-width: $tablet) {
        transform: scale(1.6);
      }

      @media (min-width: $laptop) {
        transform: scale(1.4);
      }
    }

    .welcome__itemInner {
      @media (min-width: $laptop) {
        transform: rotate(9deg);
      }
    }
  }
}

.welcome__item {
  width: 240px;
  transition: 0.2s;

  @media (min-width: $tablet) {
    width: 240px;
  }

  @media (min-width: $laptop) {
    width: 322px;
  }
}

.welcome__item--1 {
  transform: translate(0, -30px);

  @media (min-width: $tablet) {
    transform: translate(0, 30px);
  }

  .welcome__itemInner {
    transform: rotate(5deg);

    @media (min-width: $tablet) {
      transform: rotate(9deg);
    }
  }
}

.welcome__item--2 {
  transform: translate(0, -20px);

  @media (min-width: $tablet) {
    transform: translate(0, 60px);
  }

  .welcome__itemInner {
    transform: rotate(-7deg);

    @media (min-width: $tablet) {
      transform: rotate(-14deg);
    }
  }
}

.welcome__item--3 {
  transform: translate(0, 30px);

  @media (min-width: $tablet) {
    transform: translate(0, 100px);
  }

  .welcome__itemInner {
    transform: rotate(10deg);

    @media (min-width: $tablet) {
      transform: rotate(20deg);
    }
  }
}

.welcome__item--4 {
  transform: translate(0, 20px);

  @media (min-width: $tablet) {
    transform: translate(0, 60px);
  }

  .welcome__itemInner {
    transform: rotate(-12deg);

    @media (min-width: $tablet) {
      transform: rotate(-25deg);
    }
  }
}

.welcome__item--5 {
  transform: translate(30px, 0);

  @media (min-width: $tablet) {
    transform: translate(30px, 120px);
  }

  .welcome__itemInner {
    transform: rotate(8deg);

    @media (min-width: $tablet) {
      transform: rotate(16deg);
    }
  }
}

.welcome__item--6 {
  transform: translate(0, 30px);

  @media (min-width: $tablet) {
    transform: translate(0, 50px);
  }

  .welcome__itemInner {
    transform: rotate(-7deg);

    @media (min-width: $tablet) {
      transform: rotate(-17deg);
    }
  }
}

.welcome__itemInner {
  transition: 0.2s;
}

.welcome__itemWrap {
  display: block;
  position: relative;
  background: none;
  transform: scale(0.7);
  transition: 0.2s;

  @media (min-width: $tablet) {
    transform: scale(1);
  }

  @media (min-width: $laptop) {
    transform: scale(1);
  }
}

.welcome__itemImage {
  display: block;
  position: relative;
  transition: 0.2s;

  @media (min-width: $laptop) {
    transition: 0.2s;
  }

  img {
    border-radius: 20px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    box-shadow: inset 1px 1px white;
  }
}

.welcome__itemImageText {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.welcome__button {
  margin-top: 40px;
  text-align: center;
  pointer-events: none;

  @media (min-width: $tablet) {
    margin-top: 25px;
    text-align: left;
    order: 20;
  }

  @media (min-width: $laptop) {
    margin-top: 40px;
  }

  .button {
    padding-top: 14px;
    padding-bottom: 17px;
    font-size: 20px;
    pointer-events: auto;

    @media (min-width: $tablet) {
      min-width: 190px;
    }

    @media (min-width: $laptop) {
      min-width: 260px;
      padding-top: 14px;
      padding-bottom: 20px;
      font-size: 30px;
      border-radius: 17px;
    }
  }
}

.welcome__aside {
  margin-top: 30px;

  @media (min-width: $tablet) {
    position: absolute;
    top: 70px;
    right: 50%;
    transform: translateX(365px);
    width: 270px;
    margin: 0;
    padding: 0;
  }

  @media (min-width: $laptop) {
    top: 120px;
    transform: translateX(600px);
    width: 320px;
  }
}

.welcome__count {
  padding: 18px 20px 20px;
  text-align: center;
  background: linear-gradient(270deg, rgba(213, 255, 192, 0.4) 0%, rgba(131, 255, 180, 0.4) 92.86%);
  border-radius: 20px;
}

.welcome__countText {
  font-size: 17px;
  line-height: 120%;
  background-image: linear-gradient(274.22deg, #000000 2.01%, #04E061 199.18%);
  color: transparent;
  background-clip: text;

  @media (min-width: $tablet) {
    font-size: 13px;
  }

  @media (min-width: $laptop) {
    font-size: 17px;
  }
}

.welcome__countText--number {
  font-weight: 700;
  font-size: 45px;
  line-height: 100%;
  letter-spacing: -0.05em;
  background-image: linear-gradient(90deg, #000000 0%, #04E061 100%);

  @media (min-width: $tablet) {
    font-size: 30px;
  }

  @media (min-width: $laptop) {
    font-size: 45px;
  }
}

.welcome__countText--bottom {
  margin-top: 9px;
  font-weight: 500;
  font-size: 20px;
  line-height: 110%;

  @media (min-width: $tablet) {
    font-size: 15px;
  }

  @media (min-width: $laptop) {
    font-size: 20px;
  }
}

.welcome__bottom {
  @media (min-width: $tablet) {
    order: 40;
  }
}

.welcome__bottomInner {
  padding: 80px 0 0;

  @media (min-width: $tablet) {
    padding: 150px 0 0;
  }

  @media (min-width: $laptop) {
    padding: 170px 0 0;
  }
}

.welcome__bottomText1 {
  padding: 0 40px;

  @media (min-width: $tablet) {
    font-size: 20px;
    line-height: 120%;
    letter-spacing: -0.04em;
  }

  @media (min-width: $laptop) {
    padding: 0;
    font-size: 30px;
    line-height: 125%;
    letter-spacing: -0.03em;
  }
}

.welcome__bottomText2 {
  margin: 20px auto 0;
  font-weight: 500;
  font-size: 35px;
  line-height: 105%;
  letter-spacing: -0.04em;

  @media (min-width: $tablet) {
    font-size: 45px;
    line-height: 100%;
    letter-spacing: -0.05em;
  }

  @media (min-width: $laptop) {
    width: 1100px;
    font-size: 75px;
  }

  span {
    color: $purple;
  }
}
//@import "../../styles/mixins";

._reactTemplate {

}
@mixin bgi($image, $format) {
  background-image: url('img/_template/' + $image + '.' + $format);

  @supports (background-image: url('test.webp')) {
    background-image: url('img/_template/' + $image + '.webp');
  }
}
// @include bgi('background', 'png');


._template {


  @media (min-width: $tablet) {

  }

  @media (min-width: $laptop) {

  }
}