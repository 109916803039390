@keyframes rotate1 {
  from {transform: rotate(10deg)}
  to {transform: rotate(-10deg)}
}

@keyframes rotate2 {
  from {transform: rotate(5deg)}
  to {transform: rotate(-5deg)}
}

@keyframes rotate360 {
  from {transform: rotate(0)}
  to {transform: rotate(360deg)}
}

@keyframes rotate360left {
  from {transform: rotate(0)}
  to {transform: rotate(-360deg)}
}

@keyframes scale {
  from {transform: scale(1.05)}
  to {transform: scale(0.95)}
}

@keyframes fadeIn {
  from {opacity: 0}
  to {opacity: 1}
}

@keyframes move {
  from {transform: translateY(-10px)}
  to {transform: translateY(10px)}
}