// Border-box на html и все остальные элементы наследуют свойство.
// Позволяет добавить элемент с другим box-sizing.
html {
  height: 100%; // min-height: 100%;
  box-sizing: border-box;
  font-size: 10px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $basicFontFamily;
  font-size: 17px;
  line-height: 125%;
  letter-spacing: -0.03em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  text-align: center;

  @media (min-width: $tablet) {

  }

  @media (min-width: $laptop) {
    font-size: 30px;
    line-height: 120%;
  }
}

.page__wrapper {
  min-width: $mobile;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  min-height: 100vh;

  overflow: hidden;

  &.isStub {
    padding: 0;
  }
}

.title {
  font-weight: 500;
  font-size: 30px;
  line-height: 105%;
  letter-spacing: -0.04em;
  text-align: center;

  @media (min-width: $tablet) {
    font-size: 35px;
  }

  @media (min-width: $laptop) {
    font-size: 45px;
  }
}

.img {
  position: relative;
  display: block;

  picture {
    display: block;
    width: 100%;
    height: 100%;
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

ol,
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

.text-hidden {
  height: 0;
  font-size: 0;
  visibility: hidden;
  opacity: 0;
}

.swiper-pagination {
  pointer-events: none;
}

.swiper-pagination-bullet {
  width: 22px;
  height: 2px;
  border-radius: 0;
  background-color: $purple;
  pointer-events: auto;

  @media (min-width: $laptop) {
    width: 28px;
  }
}
