// Furura PT

@font-face {
    font-family: 'Aeroport';
    src: url("fonts/Aeroport.woff2") format("woff2"),
    url("fonts/Aeroport.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeroport';
    src: url("fonts/Aeroport-Medium.woff2") format("woff2"),
    url("fonts/Aeroport-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Aeroport';
    src: url("fonts/Aeroport-Bold.woff2") format("woff2"),
    url("fonts/Aeroport-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}